/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 17/03/2023.
 */

import React from 'react'
import Layout from '../components/layout'
import { graphql } from "gatsby";

const thanks = ({location}) => {
	return (
		<Layout location={location} title={'thanks'}>
			<div className="container-fluid" style={{height:"60vh"}}>
				<div className="row h-100" style={{}}>
					<div className="col-6 mx-auto d-flex justify-content-center align-items-center h-100" >
						<div className="text-center ">
							<h1>Hi John</h1>
							<h5>Thank you for signing up </h5>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default thanks

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
